export const styles = (theme) => {
  return {
    root: {
      width: "100%",
      backgroundColor: theme.palette.cards.backgroundColor,
      marginBottom: "20px",
      boxShadow: "0px 0px 4px #c3c3c3",
      borderRadius: "0.25rem",
      overflow: "hidden",
      cursor: "pointer",
      height: "152px",
      padding: "1px",
      [theme.breakpoints.down("641")]: {
        marginBottom: "18px",
        height: "137px",
      },
      [theme.breakpoints.down("481")]: {
        marginBottom: "14px",
        height: "103px",
      },
      [theme.breakpoints.down("420")]: {
        height: "94px",
      },
      [theme.breakpoints.down("381")]: {
        height: "83px",
        marginBottom: "12px",
      },
      [theme.breakpoints.down("321")]: {
        height: "75px",
        marginBottom: "10px",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    rootChild: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      height: "100%",
    },
    imageContainer: {
      display: "inline-block",
      overflow: "hidden",
      width: "152px",
      height: "152px",
      borderRadius: "4px",
      [theme.breakpoints.down("641")]: {
        height: "135px",
        width: "137px",
      },
      [theme.breakpoints.down("481")]: {
        height: "101px",
        width: "103px",
      },
      [theme.breakpoints.down("420")]: {
        height: "92px",
        width: "94px",
      },
      [theme.breakpoints.down("381")]: {
        height: "81px",
        width: "83px",
      },
      [theme.breakpoints.down("321")]: {
        height: "73px",
        width: "75px",
      },
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
        // backgroundColor: theme.palette.img.backgroundColor,
      },
    },
    details: {
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: `15px 15px 10px 17px`,
      width: "calc(100% - 152px)",
      height: "100%",
      position: "relative",
      [theme.breakpoints.down("641")]: {
        padding: `14px 14px 9px 17px`,
        width: "calc(100% - 137px)",
      },
      [theme.breakpoints.down("481")]: {
        width: "calc(100% - 103px)",
        padding: `12px 12px 7px 12px`,
      },
      [theme.breakpoints.down("420")]: {
        width: "calc(100% - 94px)",
      },
      [theme.breakpoints.down("381")]: {
        width: "calc(100% - 83px)",
        padding: `10px 10px 5px 12px`,
      },
      [theme.breakpoints.down("321")]: {
        width: "calc(100% - 75px)",
        padding: `9px 9px 4px 12px`,
      },
    },
    main_content: {
      width: "calc(100% - 118px)",
      paddingRight: "5px",
      [theme.breakpoints.down("481")]: {
        width: "calc(100% - 80px)",
      },
    },
    txbrand: {
      fontWeight: "normal",
      color: theme.palette.typography.texts.color,
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: "16px",
      lineHeight: "1.29",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        lineHeight: "1.13",
        fontSize: "8px",
      },
    },
    txapparel: {
      fontWeight: "500",
      color: theme.palette.typography.texts.color,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      fontSize: "20px",
      marginBottom: "25px",
      [theme.breakpoints.down("641")]: {
        lineHeight: "1.5",
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
        marginBottom: "16px",
        lineHeight: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        marginBottom: "9px",
        fontSize: "12.5px",
        lineHeight: "15px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        lineHeight: "16px",
        fontSize: "10px",
      },
    },
    related_products_box: {
      // padding: "2px 5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #e4e4e4",
      cursor: "pointer",
      width: "63px",
      height: "41px",
      [theme.breakpoints.down("641")]: {
        width: "57px",
        height: "37px",
      },
      [theme.breakpoints.down("481")]: {
        width: "43px",
        height: "28px",
      },
      [theme.breakpoints.down("421")]: {
        width: "32px",
        height: "21px",
      },
      [theme.breakpoints.down("401")]: {
        width: "32px",
        height: "21px",
      },
      [theme.breakpoints.down("381")]: {
        width: "32px",
        height: "22px",
      },
      [theme.breakpoints.down("321")]: {
        width: "26px",
        height: "19px",
      },
    },
    textExpires: {
      bottom: "5px",
      fontWeight: "500",
      color: theme.palette.typography.texts.sellingPriceColor,
      fontSize: "14px",
      [theme.breakpoints.down("641")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "9.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    related_products: {
      color: theme.palette.typography.texts.sellingPriceColor,
      textAlign: "center",
      fontSize: "18px",
      padding: "3px 0px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
    },
    related_expiry_box: {
      width: "100%",
      position: "absolute",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      bottom: 12,
      left: "17px",
      padding: "0px 31px 0px 0px",
      height: "35px",
      [theme.breakpoints.down("641")]: {
        height: "32px",
        bottom: 10,
      },
      [theme.breakpoints.down("481")]: {
        left: `${theme.spacing(1.5)}px`,
        padding: `0px 21px 0px 0px`,
        height: "26px",
        bottom: 7,
      },
      [theme.breakpoints.down("421")]: {
        height: "25px",
        bottom: 6,
      },
      [theme.breakpoints.down("401")]: {
        height: "24px",
        // bottom: 3,
      },
      [theme.breakpoints.down("381")]: {
        height: "23px",
      },
    },
    noSelect: {
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      userSelect: "none",
      " -webkit-tap-highlight-color": "transparent",
    },
  };
};
