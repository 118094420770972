import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import down_arrow from "../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import UploadBox from "../UploadBox/UploadBox";
import InputBase from "@material-ui/core/InputBase";
import {
  InputAdornment,
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  ClickAwayListener,
  Paper,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import MultiClickImage from "../MultiClickImage/MultiClickImage";
import { getStorageItem } from "../../common/localStorage";
import BillImage from "../../components/BillImage/BillImage";
import { errorRed } from "../UI/Theme";
import selected from "../../assets/icons/book_visit/selected.svg";
import nonselected from "../../assets/icons/book_visit/disable-checkbox.svg";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    fontSize: "1.5rem",
    padding: "0px 24px 0px 32px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      padding: "0px 24px 0px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      padding: "0px 24px 0px 16px",
    },
  },
}))(InputBase);

const CustomTextField = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    fontSize: "1.5rem",
    padding: "0px 24px 5px 28px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      padding: "0px 24px 5px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      padding: "0px 24px 5px 12px",
    },
  },
}))(TextField);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

const MenuProps2 = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "25%",
    },
  },
};

const styles = (theme) => {
  return {
    base: {
      width: "100%",
      "& .MuiSelect-selectMenu": {
        fontSize: "20px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    pageItems: {
      width: "100%",
      verticalAlign: "baseline",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginBottom: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "12px",
      },
    },
    itemContainer: {
      display: "inline-block",
      width: "50%",
      verticalAlign: "top",
      position: "relative",
      // [theme.breakpoints.down('sm')]: {
      // 	marginBottom: '12px'
      // },
      // [theme.breakpoints.down('xs')]: {
      // 	display: 'block',
      // 	width: '100%',
      // 	marginBottom: '10px'
      // }
    },
    padd_right: {
      paddingRight: "8px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "7px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "6px",
      },
    },
    padd_left: {
      paddingLeft: "8px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "7px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "6px",
      },
    },
    select_box: {
      width: "100%",
      display: "block",
      background: "#fff",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
      position: "relative",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
      },
      "& $tx_label": {
        padding: "20px 0 0px 24px",
        fontSize: "18px",
        color: "#aaaaaa",
        display: "block",
        textAlign: "left",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
          padding: "16px 0 0px 24px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "12px",
          padding: "10px 0 0px 16px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
          padding: "10px 0 0px 16px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
          padding: "6px 0 0px 16px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "8px",
          padding: "5px 0 0px 16px",
        },
      },
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "0px",
      top: "calc(50% - 10px)",
    },
    tx_label: {},
    adornment_padding: {
      fontSize: "20px",
      color: theme.palette.typography.texts.color,
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    text_field_input: {
      "& input": {
        color: theme.palette.typography.texts.color,
        fontSize: "20px",
        height: "auto",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    date_field_input: {
      "& input": {
        height: "auto",
        letterSpacing: "normal",
        color: theme.palette.typography.texts.color,
        fontSize: "20px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      width: "100%",
      fontSize: "22px",
      lineHeight: "18px",
      marginTop: "15px",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    errorMessage: {
      ...theme.palette.errorMessage,
      position: "absolute",
      left: "38px",
      [theme.breakpoints.down("sm")]: {
        left: "30px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        left: "17px",
        fontSize: "10px",
      },
    },
    root: {},
    labelCenter: {
      transform: "translate(0px, 19px)",
      [theme.breakpoints.down(641)]: {
        transform: "translate(0px, 15px)",
      },
      [theme.breakpoints.down(481)]: {
        transform: "translate(0px, 8px)",
      },
      [theme.breakpoints.down(381)]: {
        transform: "translate(0px, 7px)",
      },
      [theme.breakpoints.down(361)]: {
        transform: "translate(0px, 9px)",
      },
    },
    formControl: {
      height: "100%",
      "& :first-child": {
        paddingRight: "10px",
      },
      "& .MuiFormControl-root": {
        height: "92px",
        [theme.breakpoints.down(641)]: {
          height: "80px",
        },
        [theme.breakpoints.down(481)]: {
          height: "54px",
        },
        [theme.breakpoints.down(381)]: {
          height: "46px",
        },
        [theme.breakpoints.down(321)]: {
          height: "40px",
        },
      },
      "& .MuiSelect-selectMenu": {
        padding: 0,
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
    },
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    textPincodeWrap: {
      margin: "0px",
      padding: "0px",
      display: "block",
      position: "relative",
    },
    brand_input: {
      "& input": {
        color: theme.palette.typography.texts.color,
        fontSize: "20px",
        height: "auto",
        padding: "0px",
        paddingLeft: "24px",
        [theme.breakpoints.down(641)]: {
          paddingLeft: "24px",
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          paddingLeft: "16px",
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    textPincode: {
      borderRadius: "5px",
      // border: "solid 1px #e4e4e4",
      margin: "23px auto",
      display: "block",
      width: "100%",
      padding: "0 0 0 11px",
      "&& input": {
        fontSize: "20px",
        lineHeight: "13.5px",
      },
      [theme.breakpoints.down(641)]: {
        margin: "23px auto",
        "&& input": {
          fontSize: "16px",
          lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
        margin: "12px auto",
        "&& input": {
          fontSize: "14px",
          lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
        margin: "11px auto",
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        margin: "11px auto",
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        margin: "8px auto",
        "&& input": {
          fontSize: "12.5px",
          lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
          lineHeight: "13.5px",
        },
      },
      "& label": {
        paddingLeft: "14px",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px",
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    selectListUl: {
      margin: "0px",
      padding: "0px",
      display: "block",
      listStyle: "none",
      position: "absolute",
      top: "100px",
      background: "#fffefe",
      border: "1px solid #ccc",
      borderRadius: 4,
      maxHeight: "200px",
      overflowY: "scroll",
      zIndex: "499",
      width: "100%",
      [theme.breakpoints.down("641")]: {
        // width: "93.5%",
        top: "88px",
        maxHeight: "200px",
      },
      [theme.breakpoints.down("481")]: {
        // width: "93%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("421")]: {
        // width: "91%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("401")]: {
        // width: "90%",
        top: "60px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("381")]: {
        // width: "90%",
        top: "50px",
        maxHeight: "180px",
      },
      [theme.breakpoints.down("321")]: {
        // width: "88%",
        top: "43px",
        maxHeight: "120px",
      },
    },
    less: {
      overflow: "auto",
    },
    selectListLi: {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "10px 10px",
      display: "block",
      fontSize: "0.7rem",
      textAlign: "left",
      cursor: "pointer",
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${selected})`,
      "mask-image": `url(.${selected})`,
      mask: `url(.${selected}) no-repeat center / contain`,
      "-webkit-mask": `url(.${selected}) no-repeat center / contain`,
    },
    disable_checkbox: {
      backgroundColor: "#b6babd",
      " -webkit-mask-image": `url(.${nonselected})`,
      "mask-image": `url(.${nonselected})`,
      mask: `url(.${nonselected}) no-repeat center / contain`,
      "-webkit-mask": `url(.${nonselected}) no-repeat center / contain`,
    },
    cssLabel: {
      color: "#aaaaaa",
      "&.focused": {
        color: "#aaaaaa",
      },
      "&.shrink": {
        transform: "translate(1px, 12px) scale(0.75)",
        [theme.breakpoints.down("481")]: {
          transform: "translate(1px, 16px) scale(0.75)",
        },
        [theme.breakpoints.down("381")]: {
          transform: "translate(1px, 14px) scale(0.75)",
        },
        [theme.breakpoints.down("321")]: {
          transform: "translate(1px, 18px) scale(0.75)",
        },
      },
    },
    cssUnderline: {
      "&:after": {
        borderBottom: "none",
      },
      "&&&&:before": {
        borderBottom: "none",
      },
    },
    input: {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 1000px white inset",
      },
    },
    checkbox: {
      display: "inline-block",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      [theme.breakpoints.down(481)]: {
        width: "28px",
        height: "28px",
      },
      [theme.breakpoints.down(401)]: {
        width: "27px",
        height: "27px",
      },
      [theme.breakpoints.down(381)]: {
        width: "21px",
        height: "21px",
      },
    },
    checkboxText: {
      fontSize: 20,
      // marginTop: 18,
      marginLeft: 20,
      [theme.breakpoints.down(641)]: {
        fontSize: 18,
        // marginTop: 19,
        marginLeft: 20,
      },
      [theme.breakpoints.down(481)]: {
        fontSize: 14,
        // marginTop: 12,
        marginLeft: 20,
      },
      [theme.breakpoints.down(421)]: {
        fontSize: 13,
        // marginTop: 12,
        marginLeft: 20,
      },
      [theme.breakpoints.down(401)]: {
        fontSize: 12,
        // marginTop: 12,
        marginLeft: 20,
      },
      [theme.breakpoints.down(381)]: {
        fontSize: 12,
        // marginTop: 7,
        marginLeft: 20,
      },
      [theme.breakpoints.down(361)]: {
        fontSize: 12,
        // marginTop: 8,
        marginLeft: 20,
      },
      [theme.breakpoints.down(321)]: {
        fontSize: 11,
        // marginTop: 6,
        marginLeft: 20,
      },
    },
    paper: {
      width: "100%",
      border: "solid 1px #e4e4e4",
      borderRadius: "4px",
      boxShadow: "none",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: { height: "54px" },
      [theme.breakpoints.down(381)]: { height: "46px" },
      [theme.breakpoints.down(321)]: { height: "40px" },
      "& $tx_label": {
        padding: "20px 0 0px 24px",
        fontSize: "18px",
        color: "#aaaaaa",
        display: "block",
        textAlign: "left",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
          padding: "16px 0 0px 24px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "12px",
          padding: "10px 0 0px 16px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
          padding: "10px 0 0px 16px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
          padding: "6px 0 0px 16px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "8px",
          padding: "5px 0 0px 16px",
        },
      },
    },
    form_item: {
      width: "100%",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("321")]: {
        marginBottom: "10px",
      },
    },
  };
};

const LongBill = (props) => {
  const { classes } = props;
  const {
    allLocations,
    allBrands,
    multiClickImages,
    selectedLocation,
    selectedBrand,
    enteredBillNumber,
    selectedBillDate,
    enteredBillAmount,
    submitBtnStatus,
    allImageSize,
    handleImageSelection,
    uploadPercent,
    deleteHandler,
    cancelUploadHandler,
    submitBtnHandler,
    stopSelection,
    numberOfDays,
  } = props;
  const [focus, setFocus] = useState(false);
  const [brand, setBrand] = useState("");
  useEffect(() => {
    const brands = props.allBrands
      .filter((x) => selectedBrand === x.id)
      .map((l) => l.brand_name);
    setBrand(brands.join(","));
    return () => {};
  }, []);

  const [singleBillImagePopup, setsingleBillImagePopup] = useState(false);
  const [singleBillImageSelected, setsingleBillImageSelected] = useState(null);

  const today = new Date();
  const day = 86400000;
  const minDate = new Date(today - numberOfDays * day);
  const [billNumber, setbillNumber] = useState(false);

  const handleBillImageClickedClosed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setsingleBillImagePopup(false);
    setsingleBillImageSelected(null);
  };

  //clicked on the image to view the details of single click bill image uploaded
  const handleBillImageClicked = (e, imageSrc, isPdf) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPdf) {
      window.open(imageSrc);
    } else {
      setsingleBillImageSelected(imageSrc);
      setsingleBillImagePopup(true);
    }
  };

  let done_button = null;
  if (multiClickImages.length > 0) {
    done_button = (
      <Grid style={{ width: "100%" }}>
        <Button
          className={classes.doneBtn}
          disableRipple
          disabled={submitBtnStatus}
          color="primary"
          variant="contained"
          type="submit"
        >
          SUBMIT BILL
        </Button>
      </Grid>
    );
  }

  const filterBrands = [
    ...allBrands.filter((b) =>
      b.brand_name.toLowerCase().includes(brand.toLowerCase())
    ),
  ];
  const totalBrands = [
    ...filterBrands
      .filter((x) => selectedBrand === x.id)
      .sort(function (a, b) {
        if (a.brand_name < b.brand_name) {
          return -1;
        }
        if (a.brand_name > b.brand_name) {
          return 1;
        }
        return 0;
      }),
    ...filterBrands
      .filter((x) => selectedBrand !== x.id)
      .sort(function (a, b) {
        if (a.brand_name < b.brand_name) {
          return -1;
        }
        if (a.brand_name > b.brand_name) {
          return 1;
        }
        return 0;
      }),
  ];

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      libInstance={moment}
      locale={"en"}
    >
      <div className={classes.base}>
        <form onSubmit={submitBtnHandler}>
          <div className={classes.pageItems + " " + classes.select_box}>
            <FormControl fullWidth className={classes.formControl}>
              <label htmlFor="locations" className={classes.tx_label}>
                Choose Location
              </label>
              <Select
                name="location"
                labelId="locations"
                id="locations"
                value={selectedLocation}
                onChange={(e) => props.handleSelectChange(e)}
                input={<CustomInput />}
                IconComponent={() => (
                  <img
                    className={classes.down_icon}
                    src={down_arrow}
                    alt=""
                    width="10"
                    height="6"
                  />
                )}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value={0}>
                  Select Location
                </MenuItem>
                {allLocations.length > 0 &&
                  allLocations.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.location_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {props.validationLocation.error &&
            props.validationLocation.touched && (
              <Typography className={classes.errorMessage}>
                {props.validationLocation.message}
              </Typography>
            )}

          {/* <div className={classes.pageItems + ' ' + classes.select_box}>
						<FormControl fullWidth className={classes.formControl}>
							<label htmlFor="brands" className={classes.tx_label}>
								Choose Brand
							</label>
							<Select
								name="brand"
								labelId="brands"
								id="brands"
								value={selectedBrand}
								onChange={(e) => props.handleSelectChange(e)}
								input={<CustomInput />}
								IconComponent={() => (
									<img className={classes.down_icon} src={down_arrow} alt="" width="10" height="6" />
								)}
								MenuProps={MenuProps2}
							>
								<MenuItem disabled value={0}>
									Select Brand
								</MenuItem>
								{allBrands.length > 0 &&
									allBrands.map((brand) => (
										<MenuItem key={brand.id} value={brand.id}>
											{brand.brand_name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						{props.validationBrand.error &&
						props.validationBrand.touched && (
							<Typography className={classes.errorMessage}>{props.validationBrand.message}</Typography>
						)}
					</div> */}
          <ClickAwayListener
            onClickAway={(e) => {
              setFocus(false);
              const brands = props.allBrands
                .filter((x) => selectedBrand === x.id)
                .map((l) => l.brand_name);
              setBrand(brands.join(","));
            }}
          >
            <div className={classes.form_item} style={{ position: "relative" }}>
              <Paper className={classes.paper}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  autoComplete="false"
                >
                  {focus ? (
                    selectedLocation ? (
                      <label htmlFor="brands" className={classes.tx_label}>
                        Choose Brand
                      </label>
                    ) : (
                      <label
                        htmlFor="brands"
                        style={{ color: "red" }}
                        className={classes.tx_label}
                      >
                        Please select location
                      </label>
                    )
                  ) : (
                    <label htmlFor="brands" className={classes.tx_label}>
                      Choose Brand
                    </label>
                  )}
                  <TextField
                    className={classes.brand_input}
                    id={"brands"}
                    value={brand}
                    inputProps={{
                      maxLength: 12,
                      type: "text",
                    }}
                    // placeholder="Choose Brands"
                    onFocus={(e) => {
                      setFocus(true);
                      setBrand("");
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    onChange={(e) => {
                      setBrand(e.target.value || "");
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </Paper>
              {focus && selectedLocation ? (
                <ul
                  className={classnames(classes.selectListUl, {
                    [classes.less]: focus,
                  })}
                >
                  {totalBrands &&
                    totalBrands.length > 0 &&
                    totalBrands.map((elem) => {
                      return (
                        <li
                          key={elem.id}
                          id={elem.id}
                          value={elem.id}
                          className={classnames(classes.selectListLi, {
                            [classes.less]: focus,
                          })}
                          onClick={(e) => {
                            const brands = props.allBrands
                              .filter((x) => elem.id === x.id)
                              .map((l) => l.brand_name);
                            setBrand(brands.join(","));
                            props.handleSelectChange({
                              target: {
                                value: elem.id,
                                name: "brand",
                              },
                            });
                            setFocus(false);
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid xs={1} sm={1} item>
                              <span
                                className={`${classes.checkbox} ${
                                  selectedBrand === elem.id ||
                                  selectedBrand === elem.brand_name
                                    ? classes.selected
                                    : classes.disable_checkbox
                                }`}
                              />
                            </Grid>
                            <Grid xs={11} sm={11} item>
                              <div className={classes.checkboxText}>
                                {elem.brand_name}
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    })}
                </ul>
              ) : null}
              {props.validationBrand.error && props.validationBrand.touched && (
                <Typography className={classes.errorMessage}>
                  {props.validationBrand.message}
                </Typography>
              )}
            </div>
          </ClickAwayListener>
          <div className={classes.pageItems + " " + classes.select_box}>
            <label htmlFor="billDate" className={classes.tx_label}>
              Bill Date
            </label>
            <DatePicker
              autoOk
              format="DD    -    MMM    -    yyyy"
              value={selectedBillDate}
              onChange={props.handleDateChange}
              maxDate={today}
              minDate={minDate}
              TextFieldComponent={(props) => {
                return (
                  <CustomTextField
                    {...props}
                    id="billDate"
                    inputProps={{
                      style: {
                        padding: 0,
                      },
                      autoComplete: "off",
                    }}
                    className={classes.date_field_input}
                    InputProps={{ disableUnderline: true }}
                  />
                );
              }}
            />
          </div>
          <div className={classes.pageItems}>
            <div className={classes.itemContainer + " " + classes.padd_right}>
              <div className={classes.select_box}>
                <label
                  htmlFor="billNumber"
                  style={{
                    color: props.validationBillNumber.error && errorRed,
                  }}
                  className={classnames(classes.tx_label, {
                    [classes.labelCenter]: !billNumber,
                  })}
                >
                  Bill Number
                </label>
                <CustomTextField
                  error={
                    props.validationBillNumber.error &&
                    props.validationBillNumber.touched
                  }
                  id="billNumber"
                  name="billNumber"
                  value={enteredBillNumber}
                  onChange={(e) => props.handleBillNumberChange(e)}
                  onBlur={(e) =>
                    props.onBlurMultiChange(e) +
                    setbillNumber(enteredBillNumber ? true : false)
                  }
                  onFocus={(e) => {
                    setbillNumber(true);
                  }}
                  margin="none"
                  inputProps={{
                    style: {
                      padding: 0,
                    },
                    maxLength: 20,
                    autoComplete: "off",
                  }}
                  className={classes.text_field_input}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ className: classes.tx_label }}
                />
              </div>
              {props.validationBillNumber.error &&
                props.validationBillNumber.touched && (
                  <Typography className={classes.errorMessage}>
                    {props.validationBillNumber.message}
                  </Typography>
                )}
            </div>
            <div className={classes.itemContainer + " " + classes.padd_left}>
              <div className={classes.select_box}>
                <label
                  htmlFor="billAmount"
                  style={{
                    color: props.validationBillAmount.error && errorRed,
                  }}
                  className={classes.tx_label}
                >
                  Bill Amount
                </label>
                <CustomTextField
                  error={
                    props.validationBillAmount.error &&
                    props.validationBillAmount.touched
                  }
                  id="billAmount"
                  name="billAmount"
                  value={enteredBillAmount}
                  onChange={(e) => {if(isNaN(e?.target?.value))return ; props.handleBillAountChange(e)}}
                  onBlur={(e) => props.onBlurMultiChange(e)}
                  margin="none"
                  inputProps={{
                    style: {
                      padding: 0,
                    },
                    autoComplete: "off",
                    inputMode: "decimal",
                  }}
                  className={classes.text_field_input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        disableTypography
                        disablePointerEvents
                        className={classes.adornment_padding}
                        position="start"
                      >
                        {getStorageItem("currencyCode")
                          ? getStorageItem("currencyCode")
                          : "₹"}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </div>
              {props.validationBillAmount.error &&
                props.validationBillAmount.touched && (
                  <Typography className={classes.errorMessage}>
                    {props.validationBillAmount.message}
                  </Typography>
                )}
            </div>
          </div>
          {multiClickImages.length > 0 &&
            multiClickImages.map((bill) => {
              return (
                <MultiClickImage
                  key={bill.my_id}
                  bill_src={bill.uri}
                  bill_name={bill.file && bill.file.name}
                  bill_size={bill.file && bill.file.size}
                  onImageClicked={handleBillImageClicked}
                  closeHandler={cancelUploadHandler}
                  percent={uploadPercent}
                  id={bill.my_id}
                  deleteHandler={deleteHandler}
                  isPdf={bill.pdf}
                />
              );
            })}
          {multiClickImages.length < 5 && allImageSize < 20971520 && (
            <UploadBox
              mainTitle="Upload multiple images of a single bill"
              handleClick={handleImageSelection}
              secondaryTitle="maximum 5 images"
              note="not exceeding 20MB in total"
              disabled={stopSelection}
            />
          )}
          {done_button}
        </form>
        <Dialog
          open={singleBillImagePopup}
          keepMounted
          onClose={handleBillImageClickedClosed}
          maxWidth="md"
          className={classes.dialogModal}
        >
          <BillImage
            multi={false}
            open={singleBillImagePopup}
            imgSrc={singleBillImageSelected}
            okBtnHandler={handleBillImageClickedClosed}
          />
        </Dialog>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles, { withTheme: true })(LongBill);
