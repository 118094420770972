import React, { Component } from "react";
import {
  withStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import Map from "../../../../components/Map/MapComponet";
import { styles } from "./LocationStyle";

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class LocationComponent extends Component {
  state = {
    contactus: {},
    contactNumber: "",
    emailId: "",
    merchantName: "",
    location: "",
    lng: "",
    lat: "",
    address: "",
  };

  componentDidMount = async () => {
    await this.setState({
      lng: this.props.longitude && this.props.longitude,
      address: this.props.address && this.props.address,
      lat: this.props.latitude && this.props.latitude,
      contactNumber:
        this.props.primary_contact_number && this.props.primary_contact_number,
      emailId:
        this.props.primary_contact_email && this.props.primary_contact_email,
      merchantName: this.props.merchant_name && this.props.merchant_name,
      location: this.props.location_name && this.props.location_name,
    });
  };
  openMap = (lat, lng) => {
    if ("standalone" in window.navigator && window.navigator.standalone) {
      let win = window.open(
        "https://maps.google.com/?q=" + lat + "," + lng + "",
        "_top"
      );
      win.focus();
    } else {
      let win = window.open(
        "https://maps.google.com/?q=" + lat + "," + lng + "",
        "_blank"
      );
      win.focus();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main_container + " " + classes.common_mg}>
        <div className={classes.title}>
          {this.state.merchantName} <span>({this.state.location})</span>
        </div>
        <div className={classes.content}>
          <List>
            {this.state.address && (
              <ListItemLink
                onClick={() =>
                  this.state.lat &&
                  this.state.lng &&
                  this.openMap(this.state.lat, this.state.lng)
                }
              >
                <ListItemIcon>
                  <span className={classes.img + " " + classes.locatorCss} />
                </ListItemIcon>
                <ListItemText primary={this.state.address} />
              </ListItemLink>
            )}
            {this.state.emailId && (
              <ListItemLink
                onClick={(e) => {
                  e.preventDefault();
                  window.location.assign(`mailto:${this.state.emailId}`);
                }}
              >
                <ListItemIcon>
                  <span className={classes.img + " " + classes.emailCss} />
                </ListItemIcon>
                <ListItemText primary={this.state.emailId} />
              </ListItemLink>
            )}
            {this.state.contactNumber && (
              <ListItemLink href={`tel:${this.state.contactNumber}`}>
                <ListItemIcon>
                  <span className={classes.img + " " + classes.contactCss} />
                </ListItemIcon>
                <ListItemText primary={this.state.contactNumber} />
              </ListItemLink>
            )}
          </List>
        </div>
        {this.state.lat && this.state.lng ? (
          <div
            className={classes.map_panel}
            style={{ height: "25vh", width: "100%",marginBottom:'25px' }}
            onClick={() =>
              this.state.lat &&
              this.state.lng &&
              this.openMap(this.state.lat, this.state.lng)
            }
          >
            <Map
              lat={this.state.lat}
              lng={this.state.lng}
              scrollwheel={true}
              draggable={true}
            />
          </div>
        ) : (
          <div className={classes.noLocation}>Map not available !</div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LocationComponent);
