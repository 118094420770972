import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import HeaderRedeem from "../../../components/Layout/Header/HeaderRedeem";
import MyRoute from "../../../hoc/MyRoute";
import ProductList from "../Redeem/components/ProductList";
import {
  getProductList,
  getProductDetails,
  addToCart,
  getCartItemCount,
  addCart,
  clearProductList,
  setSortBy,
  setProductView,
  clearSortBy,
  clearProductView,
  toggleFilterDrawer,
  setFromOrderFlag,
} from "./redux/action";
import { apiData } from "../../../common/common-types";
import { getStorageItem } from "../../../common/localStorage";
import { merchantDetails } from "../../Screens/Dashboard/redux/action";
import {
  changeActiveTab,
  showLoader,
  logoutUser,
} from "../../../../src/App/redux/action";
import RedeemProductList from "../Dashboard/components/RedeemProductList";
import {
  pointsRangeChangeHandler,
  rewardValueRangeChangeHandler,
  resetFilter,
  updateBrandsFilter,
  updateLocationFilter,
  updateAppliedFilter,
  getLocations,
  getBrands,
  applyRelatedProducts,
  removeRelatedProducts,
  removeBrandList,
} from "../../../components/Filter/redux/action";
import {
  resetSearchText,
  searchChangeHandler,
} from "../../../components/Search/redux/action";
import Filter from "../../../components/Filter/Filter";
import { Drawer, Grid } from "@material-ui/core";
import RedeemSubHeader from "../../../components/RedeemSubHeader/RedeemSubHeader";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import Footer from "../../../components/Layout/Footer/Footer";
import PendamicPopupModal from "../../../components/PendamicPopup/PendamicPopupModal";
import moment from "moment";

const styles = (theme) => {
  return {};
};
const mapDispatchToProps = {
  getProductList,
  getProductDetails,
  addToCart,
  getCartItemCount,
  addCart,
  merchantDetails,
  changeActiveTab,
  showLoader,
  pointsRangeChangeHandler,
  rewardValueRangeChangeHandler,
  resetFilter,
  updateAppliedFilter,
  updateBrandsFilter,
  updateLocationFilter,
  getLocations,
  getBrands,
  applyRelatedProducts,
  removeRelatedProducts,
  removeBrandList,
  resetSearchText,
  searchChangeHandler,
  logoutUser,
  clearProductList,
  setSortBy,
  setProductView,
  clearSortBy,
  clearProductView,
  toggleFilterDrawer,
  setFromOrderFlag,
};

const mapStateToProps = (state) => {
  return {
    productList: state.reducerRedeem.productList,
    productDetails: state.reducerRedeem.productDetails,
    cartItemCount: state.reducerRedeem.cartItemCount,
    finalCartArray: state.reducerRedeem.finalCartArray,
    addtoCart: state.reducerRedeem.addtoCart,
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    pointsRange: state.reducerFilter.pointsRange,
    brandIds: state.reducerFilter.brandIds,
    locationIds: state.reducerFilter.locationIds,
    rewardValueRange: state.reducerFilter.rewardValueRange,
    filterApplied: state.reducerFilter.filterApplied,
    locationList: state.reducerFilter.locationList,
    brandList: state.reducerFilter.brandList,
    productId: state.reducerFilter.productId,
    productMerchantId: state.reducerFilter.productMerchantId,
    isListView: state.reducerProductView.isListView,
    isFromOrder: state.reducerProductView.isFromOrder,
    sort_by: state.reducerProductView.sort_by,
    searchText: state.reducerSearch.searchText,
    isFilterDrawerOpen: state.reducerRedeem.isFilterDrawerOpen,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
  };
};

class RedeemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDrawer: false,
      isBottomDrawer: false,
      pointsDynamicName: "",
      noRecords: "",
      isLoader: false,
      isModalVisible: "1"
    };
  }

  componentDidMount = async () => {
    const { showLoader, changeActiveTab } = this.props;
    this.setState({
      isModalVisible: this.props.merchantDetailsResponse?.is_earn_burn_enabled

    })

    changeActiveTab(2);

    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);

      await this.props.getCartItemCount(bodyFormData);

      const data = new URLSearchParams();
      data.append("userId", JSON.parse(getStorageItem("user")).id);
      data.append("merchant_id", apiData.merchant_id);
      data.append("platform", apiData.platform);
      data.append("customer_id",getStorageItem('user') && JSON.parse(getStorageItem('user')).id,);
      await this.props.merchantDetails(data);
      if (
        this.props.isFromOrder ||
        !this.props.productList.response ||
        (this.props.productList.response.products &&
          this.props.productList.response.products.length === 0)
      ) {
        showLoader(true);
        await this.getProducts();
      }

      this.setState({
        pointsDynamicName: getStorageItem("pointShortName"),
      });
      if (this.props.locationList.length === 0) {
        this.getLocations();
      }
      if (
        this.props.locationIds.toString() !== "" &&
        this.props.brandIds.toString() === ""
      ) {
        this.getBrands();
      }
    } else {
      this.props.logoutUser();
    }
    // -------------------------------Refreesh product list after 30 min -----------------------------------------
    let lastTimeStamp = window.localStorage.getItem("lastTimeStamp");
    var startTime = moment(lastTimeStamp, "HH:mm:ss a");
    let currentTime = moment().format("HH:mm:ss a");
    var endTime = moment(currentTime, "HH:mm:ss a");
    var duration = moment.duration(endTime.diff(startTime));
    var Minutes = parseInt(duration.asMinutes());
    this.timer = setInterval(() => {
      if (Minutes >= 25) {
        this.getProducts();
      }
    }, 50000);

    // -------------------------------eoc Refreesh product list -----------------------------------------

  }
  
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.searchText !== prevProps.searchText && this.props.searchText === '') {
  //     this.getProducts();
  //   }
  // }

  getBrands = () => {
    const location_id = this.props.locationIds.toString();
    if (getStorageItem("user")) {
      if (location_id !== "") {
        const data = new FormData();
        data.append("customer_id", JSON.parse(getStorageItem("user")).id);
        data.append("merchant_id", apiData.merchant_id);
        data.append("platform", apiData.platform);
        data.append("location_id", location_id);
        this.props.getBrands(data);
      }
    } else {
      this.props.logoutUser();
    }
  };
  getLocations = async () => {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("customer_id", getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    await this.props.getLocations(data);
    if (this.props.locationIds.length !== 0) {
      this.getBrands();
    }
  };
  openFilterDrawer = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer(true);
    this.setState(
      {
        filterDrawer: true,
      },
      () => {
        if (this.props.locationList.length === 0) {
          this.getLocations();
        }
      }
    );
  };

  closeFilterDrawer = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer(false);
    this.setState({
      filterDrawer: false,
    });
  };

  //change filter values
  filterChangeHandler = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "brandIds") {
      // if (this.props.brandIds.length === 0) {
      //   value.shift();
      // }
      this.props.updateBrandsFilter(value);
    } else if (name === "locationIds") {
      // if (this.props.locationIds.length === 0) {
      //   value.shift();
      // }
      if (value.length === 0) {
        this.props.removeBrandList();
      }

      await this.props.updateLocationFilter(value);
      this.getBrands();
    }
  };

  pointsRangeChangeHandler = (e, value) => {
    this.props.pointsRangeChangeHandler(value);
  };

  rewardValueRangeChangeHandler = (e, value) => {
    this.props.rewardValueRangeChangeHandler(value);
  };

  componentWillUnmount() {
    // this.props.resetFilter();
    // this.props.clearProductList();
    // this.props.resetSearchText();
  }

  applyFilter = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer(false);
    this.setState(
      {
        filterDrawer: false,
        isLoader: true,
      },
      async () => {
        await this.props.updateAppliedFilter(true);
        this.getProducts();
      }
    );
  };

  resetFilter = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer(false);
    this.setState(
      {
        filterDrawer: false,
        isLoader: true,
      },
      async () => {
        await this.props.resetFilter();
        this.getProducts();
      }
    );
  };
  getProducts = async () => {
    const { sort_by } = this.props;
    const filter = {
      points: {
        min: this.props.pointsRange[0],
        max: this.props.pointsRange[1],
      },
      price: {
        min: this.props.rewardValueRange[0],
        max: this.props.rewardValueRange[1],
      },
      brand:
        this.props.brandIds.length !== 0
          ? this.props.brandIds.toString()
          : null,
      location:
        this.props.locationIds.length !== 0
          ? this.props.locationIds.toString()
          : null,
    };

    let formData = new URLSearchParams();
    formData.append("customer_id", JSON.parse(getStorageItem("user")).id);
    formData.append("platform", apiData.platform);
    formData.append("appVersion", 4.0);
    formData.append("last_id", 0);
    formData.append("merchant_id", apiData.merchant_id);
    formData.append("current_date", "");
    formData.append("sort_by", sort_by);
    formData.append(
      "refine_by",
      this.props.filterApplied ? JSON.stringify(filter) : null
    );
    formData.append(
      "search_by",
      this.props.searchText !== "" ? this.props.searchText : ""
    );

    if (this.props.productId && this.props.productMerchantId) {
      formData.append("product_id", this.props.productId);
      formData.append("product_merchant_id", this.props.productMerchantId);
    }

    await this.props.getProductList(formData);

    this.setState(
      {
        noRecords: this.props.productList.statusDescription,
        isLoader: false,
      },
      () => {
        this.props.setFromOrderFlag(false);
      }
    );
    // setTimeout(() => {
    //   this.setState({
    //     isLoader: false,
    //   });
    // }, 2000);
    let date = moment().format('HH:mm:ss a');
    window.localStorage.setItem("lastTimeStamp", date);
  };
  onSearchText = async (e) => {
    const re = /^[a-zA-Z][a-zA-Z\s\b]*$/;

    if (e.target.value === "") {
      // this.setState({
      //   isLoader: true,
      // });
      let value = "";
      this.props.showLoader(true);
      await this.props.searchChangeHandler(value);
      await this.getProducts();
      this.props.showLoader(false);
    } else {
      if (re.test(e.target.value)) {
        // this.setState({
        //   isLoader: true,
        // });
        let value = e.target.value;
        // this.props.showLoader(true);
        await this.props.searchChangeHandler(value);
        // await this.getProducts();
        // this.props.showLoader(false);
      }
    }
  };
  resetSearch = async (e) => {
    // this.setState({
    //   isLoader: true,
    // });
    this.props.showLoader(true);
    await this.props.resetSearchText();
    await this.getProducts();
    this.props.showLoader(false);
  };

  toggleRelatedProducts = async (e, productId, productMerchantId) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.showLoader(true);
    if (this.props.productId && this.props.productMerchantId) {
      await this.props.removeRelatedProducts();
      this.getProducts();
    } else {
      await this.props.resetSearchText();
      await this.props.applyRelatedProducts(productId, productMerchantId);
      this.getProducts();
    }
  };
  openBottomDrawer = () => {
    this.setState({
      isBottomDrawer: !this.state.isBottomDrawer,
    });
  };
  openListView = () => {
    this.props.setProductView();
  };

  sortProducts = async (selectedSortType) => {
    this.props.showLoader(true);
    this.setState({
      isBottomDrawer: !this.state.isBottomDrawer,
    });
    await this.props.setSortBy(
      selectedSortType === "Newest"
        ? 602
        : selectedSortType === "Popularity"
          ? 601
          : selectedSortType === "Oldest"
            ? 607
            : 603
    );
    await this.getProducts();
  };

  searchCall = async (e) => {
    e.preventDefault();
    this.props.showLoader(true);
    await this.getProducts();
    this.props.showLoader(false);
  };

  render() {
    const { productList, isListView, isFilterDrawerOpen } = this.props;
    const { isBottomDrawer, pointsDynamicName } = this.state;
    const headerPorps = {
      ...this.props,
      resetFilter: this.resetFilter,
      applyFilter: this.applyFilter,
      pointsRangeChangeHandler: this.pointsRangeChangeHandler,
      rewardValueRangeChangeHandler: this.rewardValueRangeChangeHandler,
      filterChangeHandler: this.filterChangeHandler,
      openFilterDrawer: this.openFilterDrawer,
      closeFilterDrawer: this.closeFilterDrawer,
      openBottomDrawer: this.openBottomDrawer,
      openListView: this.openListView,
      sortProducts: this.sortProducts,
      isBottomDrawer,
      isListView,
      isSubHeader: true,
    };
    const productListProps = {
      list:
        productList && productList.response && productList.response.products,
      toggleRelatedProducts: this.toggleRelatedProducts,
      pointsDynamicName,
      noRecords: this.state.noRecords,
      isLoader: this.state.isLoader,
      onSearchText: this.onSearchText,
      searchText: this.props.searchText,
      showLoader: this.props.showLoader,
      resetSearch: this.resetSearch,
      searchCall: this.searchCall,
      isFilterDrawerOpen,
    };

    const redeemProductListProps = {
      list:
        this.props.productList &&
        this.props.productList.response &&
        this.props.productList.response.products,
      toggleRelatedProducts: this.toggleRelatedProducts,
      noRecords: this.state.noRecords,
      showLoader: this.props.showLoader,
      isLoader: this.state.isLoader,
      onSearchText: this.onSearchText,
      searchText: this.props.searchText,
      resetSearch: this.resetSearch,
      searchCall: this.searchCall,
      isFilterDrawerOpen,
    };
    return (
      <Fragment>

        <HeaderRedeem
          {...headerPorps}
          backBtnStatus={this.props.productId && this.props.productMerchantId}
          backBtnClickHandler={this.toggleRelatedProducts}
        />
        <AlertMessage />
        <MyRoute
          billUploadStatus={this.props.uploadingStatus}
          style={isFilterDrawerOpen && { overflow: "hidden" }}
        >
          {isListView ? (
            <RedeemProductList {...redeemProductListProps} {...headerPorps} />
          ) : (
            <ProductList {...productListProps} {...headerPorps} />
          )}
          {this.state.filterDrawer && (
            <Drawer
              anchor={"right"}
              open={this.state.filterDrawer}
              onClose={this.closeFilterDrawer}
              variant="temporary"
            >
              <Filter
                selectedLocationIds={this.props.locationIds}
                selectedBrandIds={this.props.brandIds}
                selectedPointsRange={this.props.pointsRange}
                selectedRewardValueRange={this.props.rewardValueRange}
                allLocations={this.props.locationList}
                allBrands={this.props.brandList}
                onChangeHandler={this.filterChangeHandler}
                pointsRangeChangeHandler={this.pointsRangeChangeHandler}
                rewardValueRangeChangeHandler={
                  this.rewardValueRangeChangeHandler
                }
                handleApplyBtn={this.applyFilter}
                handleResetBtn={this.resetFilter}
              />
            </Drawer>
          )}
        </MyRoute>
        <Grid item>
          <Footer />
        </Grid>

        <PendamicPopupModal
          isVisible={this.props.merchantDetailsResponse && this.props.merchantDetailsResponse?.is_burn_enabled}
          merchantDetails={this.props.merchantDetailsResponse}
          message={this.props.merchantDetailsResponse && this.props.merchantDetailsResponse?.burn_restriction_message}
        />

      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(RedeemContainer)));
