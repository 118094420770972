
import React, { Component, Fragment, Modal, Share } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import shapeCircular from "../../../../assets/icons/referFriend/shapeCircular.png";
import giftIcon from "../../../../assets/icons/referFriend/giftIcon.svg";
import formIcon from "../../../../assets/icons/referFriend/formIcon.svg";
import shareIconwhite from "../../../../assets/icons/referFriend/shareIconwhite.svg";
import farwardArrow from "../../../../assets/icons/referFriend/farwardArrow.svg";
import rectangleIcon from "../../../../assets/icons/referFriend/rectangleshapeIcon.png";
import personsbg from "../../../../assets/icons/referFriend/personsbg.svg";
import personsIcon from "../../../../assets/icons/referFriend/personsIcon.png";
import Typography from "@material-ui/core/Typography";
import { apiData, WHATSAPP_LINK, WHATSAPP_SHARING_LINK } from "../../../../common/common-types";
import { stockAPI } from "../../../../common/axiosInstance";
import { getStorageItem } from "../../../../common/localStorage";
import { Button, Dialog } from "@material-ui/core";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import referGiftIcon from "../../../../assets/icons/referFriend/referGiftIcon.svg"
const styles = (theme) => {
    return {
        wrapper: {
            objectFit: "contain",
            backgroundColor: theme.palette.globalBackground,
            paddingBottom: "100px",
            [theme.breakpoints.down("641")]: {
                paddingBottom: "50px",
            },
            [theme.breakpoints.down("481")]: {
                paddingBottom: "50px",
            },
            [theme.breakpoints.down("421")]: {
                paddingBottom: "50px",
            },
            [theme.breakpoints.down("401")]: {
                paddingBottom: "50px",
            },
            [theme.breakpoints.down("381")]: {
                paddingBottom: "50px",

            },
            [theme.breakpoints.down("361")]: {
                paddingBottom: "50px",
            },
            [theme.breakpoints.down("321")]: {
                paddingBottom: "50px",

            },
        },
        multiBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between',
        },
        WrapperBox: {
            width: '90%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        iconWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            width: "65px",
            height: "65px",
            borderRadius: "100%",
            backgroundColor: theme.palette.primary.main,
            marginTop: "20px",

        },
        subLabel: {
            height: "85px",
            width: "100px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            textAlign: "center",
            marginTop: '10px',
            color: theme.palette.typography.texts.color,
            fontSize: "16px",
            [theme.breakpoints.down("641")]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "11.5px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "11px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "10.5px",
            },
            [theme.breakpoints.down("361")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "8px",
            },
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        btn: {
            width: "90%",
            height: '43px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
        },
        btnText: {
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.palette.dashBoardheadText.color,
            fontSize: "22px",
            [theme.breakpoints.down("641")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "12px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "12px",

            },
        },
        bottomLink: {
            padding: "1px",
            paddingBottom: "20px",
            display: 'flex',
            justifyContent: 'center',
        },
        bottomLinkText: {
            marginTop: '15px',
            fontSize: "14px",
            textDecoration: "underline",
            color: theme.palette.primary.main,
            [theme.breakpoints.down("641")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "10px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "10px",

            },
        },
        middleTextContainer: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            width: '99%',
            paddingBottom: "10px",
            paddingTop: '10px',

        },
        referFriendText: {
            fontSize: "22px",
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.palette.typography.texts.color,
            [theme.breakpoints.down("641")]: {
                fontSize: "18px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "17px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "17px",

            },
        },
        GetRegisterText: {
            fontSize: "20px",
            textAlign: 'center',
            color: theme.palette.typography.texts.color,
            [theme.breakpoints.down("641")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "10px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "10px",

            },

        },
        shortcutsLinkIcon: {
            width: "50px",
            height: "50px",
            position: "absolute",
            [theme.breakpoints.down("641")]: {
                width: "43px",
                height: "43px",
            },
            [theme.breakpoints.down("481")]: {
                width: "33px",
                height: "33px",
            },
            [theme.breakpoints.down("381")]: {
                width: "28px",
                height: "28px",
            },
            [theme.breakpoints.down("321")]: {
                width: "28px",
                height: "28px",
            },
        },
        referFrienTextOberoi: {
        },
        giftIconCss: {
            backgroundColor: theme.palette.referFriend.colorWhite,
            " -webkit-mask-image": `url(.${giftIcon})`,
            "mask-image": `url(.${giftIcon})`,
            mask: `url(.${giftIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
        },
        shareIconCss: {
            backgroundColor: theme.palette.referFriend.colorWhite,
            " -webkit-mask-image": `url(.${shareIconwhite})`,
            "mask-image": `url(.${shareIconwhite})`,
            mask: `url(.${shareIconwhite}) no-repeat center / contain`,
            "-webkit-mask": `url(.${shareIconwhite}) no-repeat center / contain`,
        },
        formIconCss: {
            backgroundColor: theme.palette.referFriend.colorWhite,
            " -webkit-mask-image": `url(.${formIcon})`,
            "mask-image": `url(.${formIcon})`,
            mask: `url(.${formIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${formIcon}) no-repeat center / contain`,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            padding: "5px",
            paddingTop: "10px",
        },
        middleCircleContainer: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
        },
        outerCicleCss: {
            width: "200px",
            height: "200px",
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${shapeCircular})`,
            "mask-image": `url(.${shapeCircular})`,
            mask: `url(.${shapeCircular}) no-repeat center / contain`,
            "-webkit-mask": `url(.${shapeCircular}) no-repeat center / contain`,
        },
        outerCicleLinkIcon: {
            width: "150px",
            height: "150px",
            transition: ".12s",
            "-webkit-mask-repeat": "no-repeat",
            "-webkit-mask-size": "contain",
            "mask-size ": "contain",
            [theme.breakpoints.down("641")]: {
                width: "150px",
                height: "150px",
            },
            [theme.breakpoints.down("481")]: {
                width: "150px",
                height: "150px",
            },
            [theme.breakpoints.down("381")]: {
                width: "150px",
                height: "150px",
            },
            [theme.breakpoints.down("321")]: {
                width: "150px",
                height: "150px",
            },
        },
        innercircleCss: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.referFriend.colorWhite,
            position: 'absolute',
            borderRadius: '50%',
            width: '120px',
            height: '120px',

        },
        innerCirclebg: {
            width: '90px',
            height: '90px',
            backgroundColor: theme.palette.primary.main,
            padding: "2px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
        },
        innerCircleIcon: {
            backgroundColor: theme.palette.referFriend.colorWhite,
            " -webkit-mask-image": `url(.${giftIcon})`,
            "mask-image": `url(.${giftIcon})`,
            mask: `url(.${giftIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
        },
        InnerIconlinks: {
            width: "100px",
            height: "100px",
            position: "absolute",
            [theme.breakpoints.down("641")]: {
                width: "43px",
                height: "43px",
            },
            [theme.breakpoints.down("481")]: {
                width: "40px",
                height: "40px",
            },
            [theme.breakpoints.down("381")]: {
                width: "40px",
                height: "40px",
            },
            [theme.breakpoints.down("321")]: {
                width: "40px",
                height: "40px",
            },
        },
        imageContainer1: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            padding: "5px",
            paddingTop: "10px",
            backgroundColor: theme.palette.primary.main,
        },
        referFriendText1: {
            fontSize: "22px",
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.palette.referFriend.colorWhite,
            [theme.breakpoints.down("641")]: {
                fontSize: "18px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "17px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "17px",

            },
        },
        middleTextContainer1: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            width: '99%',
            paddingBottom: "1px",
            paddingTop: '10px',

        },
        outerCicleCss1: {
            width: "230px",
            height: "230px",
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${shapeCircular})`,
            "mask-image": `url(.${shapeCircular})`,
            mask: `url(.${shapeCircular}) no-repeat center / contain`,
            "-webkit-mask": `url(.${shapeCircular}) no-repeat center / contain`,
        },
        GetRegisterText1: {
            fontSize: "20px",
            textAlign: 'center',
            color: theme.palette.referFriend.colorWhite,
            [theme.breakpoints.down("641")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("401")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "10px",

            },
            [theme.breakpoints.down("361")]: {
                fontSize: "10px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "10px",

            },

        },
        iconbg: {
            backgroundColor: theme.palette.referFriend.colorWhite,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '59px',
            height: '59px',
            borderRadius: '100%',

        },
        giftIconCss1: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${giftIcon})`,
            "mask-image": `url(.${giftIcon})`,
            mask: `url(.${giftIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
        },
        shareIconCss1: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${shareIconwhite})`,
            "mask-image": `url(.${shareIconwhite})`,
            mask: `url(.${shareIconwhite}) no-repeat center / contain`,
            "-webkit-mask": `url(.${shareIconwhite}) no-repeat center / contain`,
        },
        formIconCss1: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${formIcon})`,
            "mask-image": `url(.${formIcon})`,
            mask: `url(.${formIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${formIcon}) no-repeat center / contain`,
        },
        ticketConatiner: {
            paddingTop: '10px',
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
        },
        ticketIConCss: {
            height: '100px',
            width: '250px',
        },
        textConatiner: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center",
            position: "absolute",
            flexDirection: 'column',
            paddingBottom: '5px'
        },
        getReferText: {
            margin: "0px 0px 2px 2px",
            fontSize: '12px',
            fontWeight: 'normal',
            color: theme.palette.typography.texts.sellingPriceColor,
        },
        ReferalCodeText: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
        iconWrapper1: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            width: "100px",
            height: "100px",
            borderRadius: "100%",
            backgroundColor: " linear-gradient(to top, #f5f5f5, #ffffff)",
            marginTop: "20px",
            boxShadow: " 0px -3px 3px 0 rgba(0, 0, 0, 0.1);",
            [theme.breakpoints.down(641)]: {
                width: "100px",
                height: "100px",
            },
            [theme.breakpoints.down(481)]: {
                width: "100px",
                height: "100px",
            },
            [theme.breakpoints.down(421)]: {
                width: "70px",
                height: "70px",
            },
            [theme.breakpoints.down(381)]: {
                width: "70px",
                height: "70px",
            },
            [theme.breakpoints.down(361)]: {
                width: "70px",
                height: "70px",
            },
            [theme.breakpoints.down(321)]: {
                width: "70px",
                height: "70px",
            },
        },
        badgeCss: {
            width: "30px",
            height: "30px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            marginTop: '-100px',
            [theme.breakpoints.down(641)]: {
                width: "25px",
                height: "25px",
                marginTop: '-70px',
            },
            [theme.breakpoints.down(481)]: {
                width: "25px",
                height: "25px",
                marginTop: '-70px',
            },
            [theme.breakpoints.down(421)]: {
                width: "23px",
                height: "23px",
                marginTop: '-60px',
            },
            [theme.breakpoints.down(381)]: {
                width: "23px",
                height: "23px",
                marginTop: '-65px',
            },
            [theme.breakpoints.down(361)]: {
                width: "23px",
                height: "23px",
                marginTop: '-65px',
            },
            [theme.breakpoints.down(321)]: {
                width: "23px",
                height: "23px",
                marginTop: '-65px',
            },
        },
        badgeText: {
            color: theme.palette.referFriend.colorWhite,
            fontSize: '13px'
        },
        imageContainer2: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            padding: "5px",
            paddingTop: "10px",
            backgroundColor: "#0081c1",
        },
        iconbg1: {
            border: "solid 2px #0074ad",
            backgroundColor: theme.palette.referFriend.colorWhite,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '59px',
            height: '59px',
            borderRadius: '100%',

        },
        arrowConatiner: {
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            paddingBottom: '100px'
        },
        arrowbg: {
            height: '25px',
            width: "25px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
        },
        LineCss: {
            position: 'absolute',
            height: '2px',
            width: "40px",
            backgroundColor: theme.palette.primary.main,
        },
        farwardArrowCSS: {
            zIndex: 100,
            height: '15px',
            width: '20px', position: 'absolute',
            backgroundColor: theme.palette.referFriend.colorWhite,
            " -webkit-mask-image": `url(.${farwardArrow})`,
            "mask-image": `url(.${farwardArrow})`,
            mask: `url(.${farwardArrow}) no-repeat center / contain`,
            "-webkit-mask": `url(.${farwardArrow}) no-repeat center / contain`,
        },
        root: {
            [theme.breakpoints.down(641)]: {
                padding: "28px",
            },
            [theme.breakpoints.down(481)]: {
                padding: "21px",
            },
            [theme.breakpoints.down(381)]: {
                padding: "15px",
            },
            [theme.breakpoints.down(321)]: {
                padding: "13px",
            },
        },
        okBtn: {
            ...theme.palette.typography.doneButtons,
            marginBottom: 0,
            fontSize: "22px",
            height: "92px",
            lineHeight: "18px",
            [theme.breakpoints.down(641)]: {
                height: "80px",
                fontSize: "18px",
                lineHeight: "16px",
            },
            [theme.breakpoints.down(481)]: {
                height: "54px",
                fontSize: "16px",
                lineHeight: "12px",
            },
            [theme.breakpoints.down(421)]: {
                height: "54px",
                fontSize: "13.5px",
            },
            [theme.breakpoints.down(381)]: {
                height: "46px",
                fontSize: "13px",
                lineHeight: "12px",
            },
            [theme.breakpoints.down(361)]: {
                fontSize: "12px",
                lineHeight: "9px",
            },
            [theme.breakpoints.down(321)]: {
                height: "40px",
                fontSize: "10px",
                lineHeight: "8px",
            },
        },
        subHeading: {
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.64",
            letterSpacing: "normal",
            color: theme.palette.typography.texts.color,
            // textAlign: 'center',
            marginBottom: "30px",
            [theme.breakpoints.down(767)]: {
                lineHeight: "1.64",
                fontSize: "16px",
                marginBottom: "28px",
            },
            [theme.breakpoints.down(481)]: {
                lineHeight: "1.5",
                fontSize: "14px",
                marginBottom: "21px",
            },
            [theme.breakpoints.down(421)]: {
                fontSize: "13.5px",
            },
            [theme.breakpoints.down(381)]: {
                lineHeight: "1.5",
                fontSize: "13px",
                marginBottom: "15px",
            },
            [theme.breakpoints.down(361)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(321)]: {
                fontSize: "10px",
                lineHeight: "1.6",
                marginBottom: "13px",
            },
        },

        conatiner: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.globalBackground,
            marginBottom: '10px'
        },
        titleTextConatiner: {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleCss: {
            marginTop: '20px',
            marginBottom: '5px',
            width: '80%',
            fontFamily: " Montserrat",
            fontSize: "32px",
            fontWeight: "bold",
            lineHeight: "1.5",
            letterSpacing: "normal",
            color: theme.palette.primary.main,
            textAlign: 'center',
            [theme.breakpoints.down(767)]: {
                fontSize: "16px",
            }
        },
        titleCss1: {
            marginTop: '20px',
            marginBottom: '5px',
            width: '80%',
            fontFamily: " Montserrat",
            fontSize: "32px",
            fontWeight: "bold",
            lineHeight: "1.5",
            letterSpacing: "normal",
            color: theme.palette.referFriend.text,
            textAlign: 'center',
            [theme.breakpoints.down(767)]: {
                fontSize: "16px",
            }
        },
        subHeaderText: {
            fontFamily: " Montserrat",
            fontSize: "18px",
            fontStretch: "normal",
            fontStyle: " normal",
            lineHeight: "1.67",
            letterSpacing: "normal",
            textAlign: "center",
            margin: "0 15px 20px",
            [theme.breakpoints.down(767)]: {
                fontSize: "13px",
            }
        },
        stepsContainer: {
            display: 'flex',
            margin: "0 10px 5px"
        },
        text2Css: {
            paddingLeft: "1px",
            margin: "2px 0px 0px 5px",
            fontFamily: " Montserrat",
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.64",
            letterSpacing: "normal",
            color: theme.palette.typography.texts.color,
            // textAlign: 'center',
            marginBottom: "30px",
            [theme.breakpoints.down(767)]: {
                lineHeight: "1.64",
                fontSize: "13px",
                marginBottom: "28px",
            },
            [theme.breakpoints.down(481)]: {
                lineHeight: "1.5",
                fontSize: "13px",
                marginBottom: "21px",
            },
            [theme.breakpoints.down(421)]: {
                fontSize: "13.5px",
            },
            [theme.breakpoints.down(381)]: {
                lineHeight: "1.5",
                fontSize: "13px",
                marginBottom: "15px",
            },
            [theme.breakpoints.down(361)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(320)]: {
                fontSize: "12px",
                lineHeight: "1.6",
                marginBottom: "13px",
            },
        },
        indexBG: {
            display: 'flex',
            flexShrink: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.primary.main,
            width: "30px",
            height: "30px",
            fontWeight: 'bold',
            borderRadius: '100%',
            fontSize: "12px",
            textAlign: 'center',
            justifyContent: 'center',
            color: "white",
        },
        ticketIConCss1: {
            height: '100px',
            width: '250px',
        },
        referGiftCss: {
            width: "269px",
            height: "269px",
            objectFit: "contain",
            [theme.breakpoints.down(767)]: {
                width: "220px",
                height: "220px",
            },
        },
        howItWorkConatiner: {
            minHeight: "50px"
        },
        ticketConatiner1: {
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
        },

    };
};



class ReferFriend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileNo: "",
            isModalOpen: false,
            howItWorksData: "",
            HowItWorkContentShow: false,
        };
    }
    componentDidMount() {
        this.getHowITWorksData();
        this.setState({
            mobileNo: this.props.merchantDetails &&
                this.props.merchantDetails &&
                this.props.merchantDetails.customerDetails.mobile_number &&
                this.props.merchantDetails.customerDetails.mobile_number
        });
    }

    getHowITWorksData() {
        const formData = new URLSearchParams();
        formData.append("mobile_number", this.state.mobileNo);
        formData.append("platform", apiData.platform);
        formData.append("transactionType", 201);
        formData.append("merchant_id", apiData.merchant_id);
        formData.append("customer_id", JSON.parse(getStorageItem("user")).id);

        stockAPI(formData, "POST", "/getReferralHowItWorks", null, null, null, true)
            .then(async (res) => {
                this.setState({ howItWorksData: res.response.referral_how_it_works })
            })
    }
    onShare() {
        if (navigator.share) {
            navigator
                .share({
                    text: this.props.merchantDetails && this.props.merchantDetails.referral_text,
                    // url: document.location.href,./
                })
                .then(() => {
                    console.log('Successfully shared');
                })
                .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                });
        }
        // window.open(`${WHATSAPP_SHARING_LINK}?text=${this.props.merchantDetails && this.props.merchantDetails.referral_text}`)

    }
    copyToClipboard = () => {
        const elem = document.createElement('textarea');
        elem.value = this.props.merchantDetails.customerDetails.referral_code;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
        openAlertBox("Code copied!", "success");
    }

    render() {
        const { classes, merchantDetails } = this.props;
        const referalCode = merchantDetails && merchantDetails.customerDetails && merchantDetails.customerDetails.referral_code;
        const MERCHANT_NAME = merchantDetails && merchantDetails.merchant_name && merchantDetails.merchant_name;
        const MERCHANT_ID = apiData.merchant_id && apiData.merchant_id;
        return (
            <div className={classes.wrapper}>
                {MERCHANT_ID === 10070 ?
                    <div className={classes.imageContainer}>
                        <img src={referGiftIcon} className={classes.referGiftCss} />
                    </div>
                    : null}
                { MERCHANT_ID == 10071 ?
                    <div>
                        <div className={classes.imageContainer2}>

                            <div className={classes.middleTextContainer1}>
                                <Typography className={classes.referFriendText1}>Refer a friend & win cool rewards!</Typography>
                                <Typography className={classes.GetRegisterText1}>Get registered friend at the {MERCHANT_NAME} in just 3 simple steps</Typography>
                            </div>
                            <div className={classes.middleCircleContainer}>
                                <span
                                    className={
                                        classes.outerCicleCss1
                                    }
                                ></span>
                                <div className={classes.innercircleCss}>
                                    <div className={classes.innerCirclebg}>
                                        <span
                                            className={
                                                classes.innerCircleIcon + " " + classes.InnerIconlinks
                                            }
                                        ></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={classes.middleTextContainer}>
                            <Typography className={classes.GetRegisterText}>Get registered friend at the {MERCHANT_NAME} in just 3 simple steps</Typography>
                        </div>
                        <div className={classes.multiBox}>

                            <div className={classes.WrapperBox}>


                                <div className={classes.iconbg1}>
                                    <span
                                        className={classes.shareIconCss1 + " " + classes.shortcutsLinkIcon}
                                    ></span>

                                </div>
                                <Typography className={classes.subLabel}>Invit your friend to join {MERCHANT_NAME}.</Typography>
                            </div>
                            <div className={classes.arrowConatiner}>
                                <span
                                    className={classes.farwardArrowCSS}
                                >

                                </span>
                                <div className={classes.arrowbg}></div>
                                <div className={classes.LineCss}></div>
                            </div>
                            <div className={classes.WrapperBox}>

                                <div className={classes.iconbg1}>
                                    <span
                                        className={
                                            classes.formIconCss1 + " " + classes.shortcutsLinkIcon
                                        }
                                    ></span>
                                </div>

                                <Typography className={classes.subLabel}>Get Your friend successfully register at {MERCHANT_NAME}</Typography>
                            </div>
                            <div className={classes.arrowConatiner}>
                                <span
                                    className={classes.farwardArrowCSS}
                                >

                                </span>
                                <div className={classes.arrowbg}></div>
                                <div className={classes.LineCss}></div>
                            </div>
                            <div className={classes.WrapperBox}>

                                <div className={classes.iconbg1}>
                                    <span
                                        className={
                                            classes.giftIconCss1 + " " + classes.shortcutsLinkIcon
                                        }
                                    ></span>
                                </div>

                                <Typography className={classes.subLabel}>You & your friend both get cool rewards!</Typography>

                            </div>

                        </div>
                    </div>
                    : null}
                {/* new refer and earn screen code for oberoi and viviana */}
                {MERCHANT_ID === 36 || MERCHANT_ID === 10070 ?
                    <div className={classes.conatiner}>
                        <div className={classes.titleTextConatiner}>
                            <p className={MERCHANT_ID === 10070 ?
                                classes.titleCss1 : classes.titleCss}
                            >
                                {merchantDetails &&
                                    merchantDetails.refferal_header &&
                                    merchantDetails.refferal_header}</p>

                            <p className={classes.subHeaderText}>{merchantDetails &&
                                merchantDetails.refferal_sub_header &&
                                merchantDetails.refferal_sub_header}</p>

                            <div>
                                {merchantDetails &&
                                    merchantDetails.refferal_description &&
                                    merchantDetails.refferal_description.map((item, index) => {
                                        return (
                                            <div className={classes.stepsContainer} >
                                                <div className={classes.indexBG} >{index + 1}</div>
                                                <p className={classes.text2Css}>{item}</p>
                                            </div>
                                        )
                                    })}

                            </div>
                        </div>

                        {MERCHANT_ID === 10070 ?
                            <div
                                className={classes.ticketConatiner1}
                                onClick={(e) => {
                                    this.copyToClipboard();
                                }}
                            >
                                <img src={rectangleIcon} className={classes.ticketIConCss} />
                                <div className={classes.textConatiner}>
                                    <Typography className={classes.getReferText}>Your Referal code</Typography>
                                    <Typography ref={(textarea) => this.textArea = textarea}
                                        className={classes.ReferalCodeText}>{referalCode}</Typography>
                                    <Typography className={classes.getReferText}>TAP TO COPY</Typography>

                                </div>
                            </div>
                            : null}
                        {MERCHANT_ID === 36 ? <div className={classes.imageContainer1}>
                            <div
                                className={classes.ticketConatiner}
                                onClick={(e) => {
                                    this.copyToClipboard();
                                }}
                            >
                                <img src={rectangleIcon} className={classes.ticketIConCss} />
                                <div className={classes.textConatiner}>
                                    <Typography className={classes.getReferText}>Your Referal code</Typography>
                                    <Typography ref={(textarea) => this.textArea = textarea}
                                        className={classes.ReferalCodeText}>{referalCode}</Typography>
                                    <Typography className={classes.getReferText}>TAP TO COPY</Typography>

                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                <img src={personsbg} style={{ height: '150px', width: "300px" }} />
                                <img src={personsIcon} style={{ height: '120px', width: "250px", position: "absolute" }} />
                            </div>

                        </div>
                            : null}
                    </div>
                    : null}
                {/*----------------------------eoc----------------------------*/}

                <div className={classes.btnContainer}>
                    <div className={classes.btn}
                        onClick={() => {
                            this.onShare();
                        }}
                    >
                        <Typography className={classes.btnText}>Refer & Earn</Typography>
                    </div>
                </div>

                <div
                    onClick={() => {
                        if (MERCHANT_ID === 36 || MERCHANT_ID === 10070) {
                            this.setState({ HowItWorkContentShow: !this.state.HowItWorkContentShow });
                        } else {
                            this.setState({ isModalOpen: !this.state.isModalOpen });
                        }
                    }}
                    className={classes.bottomLink}>
                    <Typography className={classes.bottomLinkText}>How it works?</Typography>
                </div>
                <div className={classes.howItWorkConatiner}>
                    {this.state.HowItWorkContentShow ?
                        < div
                            className={classes.subHeading}
                            dangerouslySetInnerHTML={{
                                __html: this.state.howItWorksData ?
                                    this.state.howItWorksData
                                    : " <div style='text-align:center;'>No data available</div>"
                            }}
                        />
                        : null}
                </div>
                <Dialog
                    open={this.state.isModalOpen}
                    maxWidth="md"
                // className={classes.dialogModal}
                >
                    <div className={classes.root}>
                        <div
                            className={classes.subHeading}
                            dangerouslySetInnerHTML={{
                                __html: this.state.howItWorksData ?
                                    this.state.howItWorksData
                                    : " <div style='text-align:center;'>No data available</div>"
                            }}
                        >
                        </div>
                        <div className={classes.btnContainer}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.okBtn}
                                onClick={() => {
                                    this.setState({ isModalOpen: !this.state.isModalOpen })
                                }}
                            >
                                OK
                             </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ReferFriend));

