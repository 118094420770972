import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels } from "../../../common/common-types";
import MyRoute from "../../../hoc/MyRoute";
import OrderSummary from "./components/OrderSummary";
import { getStorageItem } from "../../../common/localStorage";
import AlertMessage, {
  openAlertBox,
} from "../../../common/AlertMessage/AlertMessage";
import { changeActiveTab, showLoader } from "../../../App/redux/action";
import { stockAPI } from "../../../common/axiosInstance";
import { apiData } from "../../../common/common-types";
import { resetFilter } from "../../../components/Filter/redux/action";
import { resetSearchText } from "../../../components/Search/redux/action";
import { clearSortBy } from "../Redeem/redux/action";
import moment from "moment";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";

class OrderSummaryContainer extends Component {
  state = {
    orderId:
      this.props.history.location.state && this.props.history.location.state.id
        ? this.props.history.location.state.id
        : 0,
    currentPointBalance: 0,
    totalPointsRedeem: 0,
    orderDate: null,
    orderDetails: [],
  };

  componentDidMount() {
    this.props.showLoader(true);
    this.props.changeActiveTab(2);
    this.props.resetFilter();
    // this.props.resetSearchText();
    this.props.clearSortBy();
    if (this.state.orderId !== 0) {
      const data = new FormData();
      data.append("order_id", this.state.orderId);
      data.append("platform", apiData.platform);
      data.append("merchant_id", apiData.merchant_id);
      data.append("customer_id", getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      stockAPI(data, "POST", "/getCustomerOrderDetails")
        .then((response) => {
          this.props.showLoader(false);
          if (response) {
            if (!Array.isArray(response)) {
              this.setState({
                currentPointBalance:
                  response.current_point_balance &&
                  (parseFloat(response.current_point_balance) % 1 !== 0
                    ? parseFloat(response.current_point_balance).toFixed(2)
                    : Math.floor(parseFloat(response.current_point_balance))),
                totalPointsRedeem:
                  response.total_points_redeemed &&
                  (parseFloat(response.total_points_redeemed) % 1 !== 0
                    ? parseFloat(response.total_points_redeemed).toFixed(2)
                    : Math.floor(parseFloat(response.total_points_redeemed))),
                orderDetails: response.order_details && response.order_details,
                orderDate:
                  response.order_date &&
                  moment(response.order_date).format("DD/MM/YYYY"),
              });
            } else {
              openAlertBox("No record found.", "error");
            }
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        })
        .catch((err) => {
          this.props.showLoader(false);
          openAlertBox(err && err.message, "error");
        });
    } else {
      this.props.showLoader(false);
      openAlertBox("Could not find order.", "error");
      this.props.history.replace("/dashboard");
    }
  }

  handleRedeemBtn = () => {
    this.props.history.replace("/transactions");
    this.props.changeActiveTab(3);
  };

  render() {
    const {
      currentPointBalance,
      totalPointsRedeem,
      orderDetails,
      orderId,
    } = this.state;

    return (
      <Fragment>
        <MyRoute fullHeight={this.props.history.location.state.from === '/transactions'} billUploadStatus={this.props.uploadingStatus}>
          <Header screenLabel={headerScreenLabels.orderSummary} />
          <AlertMessage />
          <OrderSummary
            handleRedeemBtn={this.handleRedeemBtn}
            orderId={orderId}
            orderDetails={orderDetails}
            totalPointsRedeem={totalPointsRedeem}
            currentPointBalance={currentPointBalance}
            orderDate={this.state.orderDate}
            merchantDetailsResponse={this.props.merchantDetailsResponse}
          />
        </MyRoute>
        {
          this.props.history.location.state.from !== '/transactions' &&
          <Grid item>
					  <Footer />
				  </Grid>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: (id) => dispatch(changeActiveTab(id)),
    showLoader: (val) => dispatch(showLoader(val)),
    resetFilter: () => dispatch(resetFilter()),
    resetSearchText: () => dispatch(resetSearchText()),
    clearSortBy: () => dispatch(clearSortBy()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryContainer);
