import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { styles } from "./BookStartStyles";
import mall_logo from "../../../../assets/icons/splash/mall_logo_bottom.png";
import whatsappicon from "../../../../assets/icons/book_visit/whatsappicon.png";

class BookVisitStart extends Component {
	componentDidMount() {
	}

	render() {
		const { classes } = this.props;

		return (
			<div style={{ display: "flex", alignItems: "center", height: "100%", backgroundColor: '#fff' }}>
				<div className={classes.startwrapper}>
        <img alt="mall" className={classes.icons} src={mall_logo}/>
        <Typography variant="h1" className={classes.heading}>
				Welcome! Book your visit to Growel's++
        </Typography>

				

				<div >
					<Button
						variant="contained"
						color="primary"
						className={classes.completeHere}
						type="submit"
						onClick={() => this.props.setPage(2)}
					>
						CONTINUE HERE
					</Button>
				</div>
				<div >
					<Button
						variant="contained"
						color="primary"
						className={classes.bookwhatsapp}
						type="submit"
						disabled
						onClick={() => {
							window.open("https://web.whatsapp.com/")
						}}
					>
						<img alt="icon" className={classes.btnicons} src={whatsappicon}/> COMING SOON ON WHATSAPP!
					</Button>
				</div>
      </div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(BookVisitStart);
