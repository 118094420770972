import React, { Component } from "react";
import classnames from "classnames";
import malllogo from "../../assets/icons/instruction_dialog/logo.png";
import logo from "../../assets/icons/instruction_dialog/mobileicon.svg";
import BOOMER1 from "../../assets/icons/more/layer-3.png";
import BOOMER2 from "../../assets/icons/more/layer-3@2x.png";
import BOOMER3 from "../../assets/icons/more/layer-3@3x.png";
import { stockAPI } from "../../common/axiosInstance";
import { Typography, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AlertMessage, {
  openAlertBox,
} from "../../common/AlertMessage/AlertMessage";
import { getStorageItem, setStorageItem } from "../../common/localStorage";

import { styles } from "./style";
import { apiData } from "../../common/common-types";
import { withRouter } from "react-router-dom";

class Optout extends Component {
  state = {
    isconfirm: false,
    isOptIn: false,
    optKey: null,
  };

  componentDidMount() {
    this.setState({
      optKey: window.location.href.split("optout/")[1] || null,
      isconfirm: getStorageItem("isconfirm")
        ? getStorageItem("isconfirm")
        : false,
      isOptIn: getStorageItem("isOptIn") ? getStorageItem("isOptIn") : false,
    });
  }

  handleYesClick = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("platform", apiData.platform);
    formData.append("opt_out_key", this.state.optKey);
    stockAPI(formData, "POST", "/optOutFromSMS", null, null, null, true)
      .then((response) => {
        if (response.statusCode != 401) {
          this.setState(
            {
              isconfirm: true,
            },
            () => {
              setStorageItem("isconfirm", true);
              openAlertBox(response.statusDescription, "success");
            }
          );
        } else {
          openAlertBox(response.statusDescription, "error");
        }
      })
      .catch((err) => {
        this.setState({
          isconfirm: false,
        });
        openAlertBox(err && err.message, "error");
      });
  };

  handleOptInClick = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("platform", apiData.platform);
    formData.append("opt_in_key", this.state.optKey);
    stockAPI(formData, "POST", "/optInForSMS", null, null, null, true)
      .then((response) => {
        if (response.statusCode != 401) {
          this.setState(
            {
              isOptIn: true,
              isconfirm: false,
            },
            () => {
              setStorageItem("isOptIn", true);
              localStorage.removeItem("isconfirm");
              openAlertBox(response.statusDescription, "success");
            }
          );
        } else {
          openAlertBox(response.statusDescription, "error");
        }
      })
      .catch((err) => {
        this.setState({
          isOptIn: false,
        });
        openAlertBox(err && err.message, "error");
      });
  };

  componentWillUnmount() {
    localStorage.removeItem("isOptIn");
    localStorage.removeItem("isconfirm");
  }

  render() {
    const { isconfirm, isOptIn } = this.state;
    const { classes } = this.props;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <AlertMessage />
        <div className={classes.innerwrapper}>
          <Grid container className={classes.headerWrapper}>
            <Grid
              item
              xs={6}
              sm={6}
              style={{ display: "table-cell", verticalAlign: "middle" }}
            >
              <img
                src={logo}
                className={classes.logo}
                onClick={() => this.props.history.replace("/")}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                textAlign: "right",
              }}
            >
              <img src={malllogo} className={classes.malllogo} />
            </Grid>
          </Grid>
          <div className={classes.imagewrapper}>
            <div className={classes.bgCss}>
              <span
                className={classnames(classes.coming_soon_img, {
                  [classes.iconCss]: !isOptIn,
                  [classes.optInCss]: isOptIn,
                })}
              />
            </div>
          </div>
          <Typography variant="h1" className={classes.heading}>
            {!isOptIn
              ? !isconfirm
                ? "Opt-out?"
                : "Opt-out successful!"
              : "Opt-in successful!"}
          </Typography>
          <Typography className={classes.coming_soon_nml}>
            {!isOptIn
              ? !isconfirm
                ? "Are you sure you want to opt-out of SMS communication?"
                : "You have opted-out from Growel's++ SMS notifications"
              : "Congratulations! You have opted-in to receive SMS communication updates from Growel's++"}
          </Typography>

          <div style={{ marginBottom: isconfirm ? "5vh" : "3vh" }} />
          {isconfirm && !isOptIn && (
            <Typography className={classes.coming_soon_nml}>
              If you did this in error you may Opt-in by clicking the button
              below.
            </Typography>
          )}
          <div className={classes.btnContainer}>
            {!isconfirm && !isOptIn ? (
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                  <Button
                    disableRipple
                    color="primary"
                    variant="contained"
                    className={classes.doneBtn}
                    onClick={this.handleYesClick}
                  >
                    YES
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    disableRipple
                    color="primary"
                    variant="contained"
                    className={classes.cancelButtons}
                    onClick={() => {
                      this.props.history.replace("/");
                    }}
                  >
                    NO
                  </Button>
                </Grid>
              </Grid>
            ) : (
              !isOptIn && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      disableRipple
                      color="primary"
                      variant="contained"
                      className={classes.doneBtn}
                      onClick={this.handleOptInClick}
                    >
                      OPT-IN NOW!
                    </Button>
                  </Grid>
                </Grid>
              )
            )}
          </div>
        </div>
        <div className={classes.bottom_panel}>
          <div className={classes.bottom_title}>Powered By</div>
          <div className={classes.icons}>
            <img
              src={BOOMER1}
              srcSet={`${BOOMER2} 2x,${BOOMER3} 3x`}
              className={classes.Layer_3}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Optout));
